<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="2" class="f_w tit">文物检索</el-col>
            <el-col :span="6">
                <el-input v-model="srhStr" size="small" maxlength="66" placeholder="请输入搜索关键词" clearable></el-input>
            </el-col>
            <!--            <el-col :span="2">-->
            <!--                <el-select v-model="size" size="small" placeholder="尺寸">-->
            <!--                    <el-option-->
            <!--                            v-for="item in options"-->
            <!--                            :key="item.value"-->
            <!--                            :label="item.label"-->
            <!--                            :value="item.value">-->
            <!--                    </el-option>-->
            <!--                </el-select>-->
            <!--            </el-col>-->
            <el-col :span="12">
                <el-button size="small" type="primary" @click="querySysOriginalDescriptionAll">检索</el-button>
                <span class="m_l1 unsatisfy">对检索结果不满意？试试<el-button size="small" type="text"
                                                                   @click="advancedQuery">高级检索</el-button></span>
            </el-col>
            <!--            <el-col :span="3">-->
            <!--                <el-button size="small" :disabled="multipleSelection.length==0" plain @click="Collection">收藏</el-button>-->
            <!--                <el-button size="small" :disabled="multipleSelection.length==0" plain @click="Borrow">借阅</el-button>-->
            <!--            </el-col>-->
            <el-col :span="4" class="text_right">
                <el-button-group>
                    <el-button size="small" plain @click="back">返回</el-button>
                    <el-button size="small" plain icon="el-icon-refresh"
                               @click="querySysOriginalDescriptionAll"></el-button>
                    <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <!--        <el-row :gutter="10" style="margin-top: 10px">-->
        <!--            <el-col :span="2" class="f_w tit">标签</el-col>-->
        <!--            <el-col :span="22">-->
        <!--                <el-tag v-for="item in biaoList" :key="item.id" @click="biaoqian(item)"-->
        <!--                        :class="['xuantiang',biaoSelect.id == item.id ? 'xuanzhog' : '']">{{ item.name }}-->
        <!--                </el-tag>-->
        <!--            </el-col>-->
        <!--        </el-row>-->
        <div class="exhibition">
            <el-collapse accordion v-model="labelNames">
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="flex_b_c exhibition-select" style="width: 100%">
                            <div class="exhibition-title">
                                <div class="xiaoshutiao"></div>
                                标签<span class="exhibition-select">{{ biaoSelect.name}} ({{total}})条</span>
                            </div>
                            <div>{{ labelNames.length==0 ? '展开' : '收缩' }}</div>
                        </div>
                    </template>
                    <div style="padding: 0 20px">
                        <el-row :gutter="20" style="margin-bottom: 20px">
                            <el-col :span="6">
                                <el-input v-model="labelName" size="small" maxlength="66" placeholder="请输入标签名称" clearable></el-input>
                            </el-col>
                            <el-col :span="3">
                                <el-button @click="labelIndexQueryAll2" style="margin-right: 60px" size="small"
                                           type="primary"
                                           icon="el-icon-search">查询
                                </el-button>
                            </el-col>
                        </el-row>
                        <div class="flex_b_c">
                            <div style="font-size: 50px; cursor: pointer;" @click="labelOn">
                                <i :class="['el-icon-arrow-left',labelPageNo==1 ? 'hui' :'blue']"></i>
                            </div>
                            <div style="width: 100%;text-align: center;color: rgb(144,147,153)" v-if="!biaoList.length">暂无数据</div>
                            <div @click="biaoqian(item)" v-for="item in biaoList" :key="item.id"
                                 :class="['checkBox xuantiang',biaoSelect.id==item.id ? 'select' :'']">
                                <el-tooltip placement="top">
                                    <div slot="content">{{ item.name }}</div>
                                    <div class="wenojsnd">{{ item.name }}</div>
                                </el-tooltip>
                            </div>
                            <div style="font-size: 50px;cursor: pointer;" @click="labelTo">
                                <i :class="['el-icon-arrow-right',labelPageNo * labelPageSize >= labelTotal ? 'hui' :'blue']"></i>
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!--展览-->
        <div class="exhibition">
            <el-collapse accordion v-model="catalogueNames">
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="flex_b_c exhibition-select" style="width: 100%">
                            <div class="exhibition-title">
                                <div class="xiaoshutiao"></div>
                                展览<span class="exhibition-select"><span v-if="Object.values(catalogueShuju).length" style="margin-right: 5px">·</span>{{ catalogueShuju.exhibitionName}} ({{total}})条</span>
                            </div>
                            <div>{{ catalogueNames.length==0 ? '展开' : '收缩' }}</div>
                        </div>
                    </template>
                    <div style="padding: 0 20px">
                        <el-row :gutter="20" style="margin-bottom: 20px">
                            <el-col :span="6">
                                <el-input v-model="catalogueName" maxlength="66" size="small" placeholder="请输入展览名称"
                                          clearable></el-input>
                            </el-col>
                            <el-col :span="3">
                                <el-button @click="queryGetCatalogue" style="margin-right: 60px" size="small"
                                           type="primary"
                                           icon="el-icon-search">查询
                                </el-button>
                            </el-col>
                        </el-row>
                        <div class="flex_b_c">
                            <div style="font-size: 50px; cursor: pointer;" @click="exhibitionOn">
                                <i :class="['el-icon-arrow-left',cataloguePageNo==1 ? 'hui' :'blue']"></i>
                            </div>
                            <div style="width: 100%;text-align: center;color: rgb(144,147,153)" v-if="!catalogueList.length">暂无数据</div>
                            <div @click="catalogueSelect(item)" v-for="item in catalogueList" :key="item.id"
                                 :class="['checkBox xuantiang',catalogueShuju.id==item.id ? 'select' :'']">
                                <div style="margin-right: 10px">·</div>
                                <el-tooltip placement="top">
                                    <div slot="content">{{ item.exhibitionName }}</div>
                                    <div class="wenojsnd">{{ item.exhibitionName }}</div>
                                </el-tooltip>
                            </div>
                            <div style="font-size: 50px;cursor: pointer;" @click="exhibitionTo">
                                <i :class="['el-icon-arrow-right',cataloguePageNo * cataloguePageSize >= catalogueTotal ? 'hui' :'blue']"></i>
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

        <div class="lbaiogao">
            <div style="margin-left: 5px" class="flex_b_c">
                <div class="iuahdiu">
                    <div class="xiaoshutiao"></div>
                    原文
                </div>
                <div style="display: flex;margin: 5px 16px 0">
                    <div @click="xuzidb(1)" :class="['left',hehsu==1 ? 'selectaud' : 'adjfu']">
                        <img width="50%" :src="shu1">
                    </div>
                    <div @click="xuzidb(2)" :class="['right',hehsu==2 ? 'selectaud' : 'adjfu']">
                        <img width="50%" :src="heng1">
                    </div>
                </div>
            </div>
            <el-row :gutter="10">
                <div v-if="hehsu == 1" style="height: calc(100vh - 430px);width: 100%;overflow-y: auto;">
                    <el-empty v-if="!fileList.length" description="暂无数据"></el-empty>
                    <div v-for="(item,index) in fileList" :key="index" style="padding: 0 10px">
                        <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                            <div class="xiaojbghi" @click="view(item.id)">
                                <div class="tupaineui">
                                    <el-image
                                            style="width: 95%;height: 136px"
                                            :src="item.url"
                                            :fit="'cover'"></el-image>
                                </div>
                                <el-tooltip placement="top">
                                    <div slot="content">{{ item.culturalName }}</div>
                                    <div class="listTitle">{{item.culturalName}}
                                    </div>
                                </el-tooltip>
                                <div class="aajfj" style="margin: 10px 12px">
                                    <div>
                                        时代：{{ item.times }}
                                    </div>
                                    <!--                        <div>-->
                                    <!--                            材质：绢本-->
                                    <!--                        </div>-->
                                </div>
                                <el-tooltip placement="top">
                                    <div slot="content">{{item.size}}</div>
                                    <div class="aajfj" style="margin: 10px 12px">
                                        尺寸：{{item.size}}
                                    </div>
                                </el-tooltip>
                            </div>
                        </el-col>
                    </div>
                </div>
            </el-row>
            <el-table
                    v-if="hehsu == 2"
                    :data="fileList"
                    size="small"
                    v-loading="loading"
                    height="calc(100vh - 430px)"
                    @selection-change="handleSelectionChange"
                    class="table" ref="tableBox">
                <el-table-column type="selection" width="50"/>
                <el-table-column v-for="(item,index) in config" :key="item.id" :prop="item.fieldEnname"
                                 :label="item.recordName"
                                 show-overflow-tooltip v-if="item.onShow==0">
                    <template slot-scope="scope">
                        <el-input-number v-if="item.fieldEnname=='sequenceNumber'" style="width: 100px" size="mini"
                                         v-model="scope.row.sequenceNumber" @change="handleChangeOrder(scope.row)"
                                         :min="1"></el-input-number>
                        <el-image v-else-if="item.fieldEnname=='url'"
                                  style="width: 60px; height: 60px"
                                  :src="scope.row.url"
                                  fit="contain"></el-image>
                        <div v-else>{{scope.row[item.fieldEnname]}}</div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain @click="view(scope.row.id)">详情</el-button>
                        <!--                    <el-button size="mini" type="primary" plain @click="download(scope.row)">下载</el-button>-->
                        <!--                    <el-button size="mini" type="primary" plain @click="print(scope.row)" >打印</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="margin-bottom: 15px;"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           :current-page="pageNo"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size="pageSize"
                           :total="total"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!--高级检索-->
        <AdvancedQuery ref="advancedQuery" @getDataList="searchGetDataList"></AdvancedQuery>
        <!--查看-->
        <DetailPage ref="detailPage"></DetailPage>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop' // 导入排序弹窗
    import AdvancedQuery from './advancedQuery' // 高级查询弹窗
    import DetailPage from './detailPage' // 查看弹窗

    export default {
        components: {DraggablePop, AdvancedQuery, DetailPage},
        data() {
            return {
                srhStr: '',
                size: '',//尺寸
                moduleId: 5,   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                fileList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                config: [],
                dateList: [],
                multipleSelection: [],//多选数据
                options: [
                    {
                        value: 1,
                        label: '200',
                    }
                ],
                biaoList: [],
                biaoSelect: {},//标签选中数据
                labelPageNo: 1,
                labelPageSize: 4,
                labelTotal: 0,
                labelNames: [], //控制标签是否展开
                labelName: "",

                catalogueShuju: {}, //展览选中数据
                catalogueNames: [], //控制是否展开
                catalogueName: '',//展览名称
                //展览分页
                cataloguePageNo: 1,
                cataloguePageSize: 4,
                catalogueTotal: 0,
                catalogueList: [],

                exhibitionShuju: {}, //目录选中数据
                activeNames: [], //控制是否展开
                exhibitionName: '',//目录名称
                // //展览分页

                hehsu: 1,//1为详情。2为表
                shu1: require("@/assets/img/shu.png"),
                heng1: require("@/assets/img/heng.png"),

            }
        },
        methods: {
            init(srhStr) {
                console.log(srhStr)
                this.srhStr = srhStr;
                this.getCatalogue()
                this.labelIndexQueryAll();
                this.querySysOriginalDescriptionAll();
            },
            labelIndexQueryAll2(){
                this.labelPageNo = 1
                this.labelIndexQueryAll();
            },
            labelIndexQueryAll() {
                this.biaoSelect = {}
                this.$axios(this.api.zlzs.labelIndexList, {
                    "name": this.labelName,
                    "state":1,
                    'pageNo': this.labelPageNo,
                    'pageSize': this.labelPageSize,
                }, 'get').then(data => {
                    if (data.status && data.data) {
                        this.biaoList = data.data.records
                        this.labelTotal = parseInt(data.data.total)
                        this.tableData()
                    }
                })
            },
            queryGetCatalogue() {
                this.cataloguePageNo = 1
                this.getCatalogue();
            },
            // 查询展览
            getCatalogue() {
                this.catalogueShuju = {}
                this.$axios(this.api.zlzs.queryExhibition, {
                    'name': this.catalogueName,
                    'pageNo': this.cataloguePageNo,
                    'pageSize': this.cataloguePageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.catalogueList = data.data.records;
                        this.catalogueTotal = parseInt(data.data.total)
                        // if (this.catalogueList.length > 0) {
                        // this.catalogueShuju.id = this.exhibitionOpt[0].id
                        // this.catalogueShuju.exhibitionName = this.exhibitionOpt[0].exhibitionName
                        // }
                        this.tableData();
                    }
                })
            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
            querySysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
                    flag: 5,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'exhibitionId') {
                                v.fieldEnname = 'exhibitionName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                        console.log("配置：", this.config);
                        this.search();
                    }
                })
            },
            // 检索
            tableData() {
                // if(this.catalogueShuju || this.biaoSelect){
                this.loading = true
                this.$axios(this.api.zlzs.culturalRelicsRetrieve, {
                    "name": this.srhStr,
                    "exhibitionId": this.catalogueShuju.id,
                    "labelId": this.biaoSelect.id,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 5,
                    'onDetails': 1,
                }, 'get').then(data => {
                    if (data && data.status) {
                        let that = this;
                        this.fileList = data.data.records
                        this.fileList.forEach(function (v) {
                            v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.url.length)
                        })
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.$refs.tableBox.doLayout();
                    }
                })
                // }

            },
            search() {
                this.pageNo = 1
                this.type = 0
                this.tableData()
            },
            // 返回
            back() {
                this.$emit('getData')
            },
            // 高级查询
            advancedQuery() {
                let config = JSON.parse(JSON.stringify(this.config))
                config.forEach(function (v,i) {
                    console.log(v);
                    if(v.recordName=="文物照片"){
                        config.splice(i,1)
                    }
                })
                this.$refs.advancedQuery.init(config)
            },
            searchGetDataList(data) {
                this.type = 1
                this.pageNo = 1
                this.dateList = data
                this.getDataList(data);
            },
            //高级查询检索返回
            getDataList(data) {
                console.log("高级查询：", data);
                this.$axios(this.api.zlzs.culturalRelicsSeniorQuery, {
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                    'flag': 5,
                    'onDetails': 1,
                    "seniorQueries": data
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.fileList = data.data.records
                        let that = this;
                        this.fileList.forEach(function (v) {
                            v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.length)
                        })
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.$refs.tableBox.doLayout();
                    }
                })
            },
            // 查看
            view(row) {
                this.$refs.detailPage.init(row, 'culturalList')
            },
            // 下载
            download(row) {
                const a = document.createElement('a')
                //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                const url = row.url;  // 完整的url则直接使用
                // 这里是将url转成blob地址，
                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    a.href = URL.createObjectURL(blob)
                    // console.log(a.href)
                    a.download = row.fileName // 下载文件的名字
                    // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                    document.body.appendChild(a)
                    a.click()
                })
            },
            //打印
            print(row) {

            },
            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.config = data;
                this.search();
                this.setShow = state;
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                if (this.type == 0) {
                    this.tableData();
                } else {
                    this.getDataList(this.dateList)
                }
            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                if (this.type == 0) {
                    this.tableData();
                } else {
                    this.getDataList(this.dateList)
                }
            },

            //收藏
            Collection() {

            },

            //借阅
            Borrow() {

            },

            //多选
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            //标签点击
            biaoqian(item) {
                // this.biaoSelect = item;
                if (this.biaoSelect.id == item.id) {
                    this.biaoSelect = {}
                } else {
                    this.biaoSelect = item;
                }
                this.tableData();
            },

            //关联
            handelRelevance() {
                console.log('关联')
            },

            //展览选中
            catalogueSelect(item) {
                if (this.catalogueShuju.id == item.id) {
                    this.catalogueShuju = {}
                } else {
                    this.catalogueShuju = item;
                }
                this.tableData();
                // this.catalogueNames = [];
            },

            //展览查询
            catalogueQuery() {

            },

            //列表
            xuzidb(num) {
                this.hehsu = num;
                if (num == 1) {
                    this.shu1 = require("@/assets/img/shu.png");
                    this.heng1 = require("@/assets/img/heng.png");
                } else {
                    this.shu1 = require("@/assets/img/shu2.png");
                    this.heng1 = require("@/assets/img/heng2.png");
                }
            },
            exhibitionOn() {
                if (this.cataloguePageNo == 1) {
                    return false
                }
                this.cataloguePageNo -= 1
                this.getCatalogue();
            },
            exhibitionTo() {
                if (this.cataloguePageNo * this.cataloguePageSize >= this.catalogueTotal) {
                    return false
                }
                this.cataloguePageNo += 1
                this.getCatalogue();
            },
            labelOn() {
                if (this.labelPageNo == 1) {
                    return false
                }
                this.labelPageNo -= 1
                this.labelIndexQueryAll();
            },
            labelTo() {
                if (this.labelPageNo * this.labelPageSize >= this.labelTotal) {
                    return false
                }
                this.labelPageNo += 1
                this.labelIndexQueryAll();
            },
        }
    }
</script>

<style scoped>
    .tit {
        padding-top: 5px;
    }

    .unsatisfy {
        font-size: 12px;
    }

    .wordIcon {
        width: 16px;
        margin: 0 4px;
    }

    .xuanzhog {
        border-color: #35FA42;
    }

    .xuantiang {
        cursor: pointer;
        display: flex;
        align-items: center;
    }


    .lbaiogao {
        margin-top: 20px;
        width: 100%;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(215, 215, 215, 100);
    }

    .iuahdiu {
        display: flex;
        align-items: center;
        margin-left: 16px;
        font-size: 20px;
        font-weight: bold;
    }

    .tupaineui {
        padding: 13px 18px;
        box-sizing: border-box;
    }

    .listTitle {
        padding: 0 10px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 600;
    }


    .left {
        width: 50px;
        height: 37px;
        line-height: 49px;
        text-align: center;
        border-radius: 4px 0 0 4px;
    }

    .right {
        width: 50px;
        height: 37px;
        line-height: 46px;
        text-align: center;
        border-radius: 0 4px 4px 0;
    }

    .selectaud {
        background: #3E8DF7;
    }

    .adjfu {
        border: 1px solid rgba(187, 187, 187, 100);
    }

    .xiaojbghi {
        float: left;
        margin: 5px 0;
        width: 100%;
        height: 280px;
        line-height: 20px;
        border-radius: 8px;
        text-align: center;
        border: 1px solid rgba(208, 208, 208, 100);
    }


    .exhibition {
        margin-top: 20px;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(208, 208, 208, 100);
    }

    .exhibition-title {
        display: flex;
        align-items: center;
        color: black;
        font-size: 20px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .exhibition-select {
        margin-left: 20px;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .checkBox {
        margin: 0 10px;
        width: 20%;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid rgba(187, 187, 187, 100);
    }

    .hui {
        color: rgba(208, 208, 208, 100);
    }

    .blue {
        color: #3E8DF7;
    }

    .select {
        border: 1px solid #3E8DF7;
    }

    .wenojsnd {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .aajfj {
        text-align: left;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
